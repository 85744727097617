// project import
import { IMenuItem, IMenuItemCategory, IMenuItemKind } from "../menu-items";
import MainLayout from "../../layout/MainLayout";
import menuItems from "..";

// ==============================|| MAIN ROUTING ||============================== //
interface IRouteNode {
  path: string;
  element?: React.ReactNode;
  children?: IRouteNode[];
}
const MainRoutes: IRouteNode = {
  path: "/",
  element: <MainLayout />,
  children: [],
};
const buildMainRoutes = (menuElement: IMenuItem, parentRoute: IRouteNode) => {
  const children = menuElement.children || [];
  const { path, element, type: kind } = menuElement;
  if (kind === IMenuItemKind.Group) {
    children.forEach((element) => {
      buildMainRoutes(element, parentRoute);
    });
  } else if (path) {
    const routeNode: IRouteNode = { path, element };
    parentRoute.children = [...(parentRoute.children || []), routeNode];
    children.forEach((element) => {
      buildMainRoutes(element, routeNode);
    });
  }
};
menuItems.items.forEach((item) => {
  if (item.category === IMenuItemCategory.Main) {
    buildMainRoutes(item, MainRoutes);
  }
});

export default MainRoutes;
