import { IMenuItem, IMenuItemKind, IMenuItemCategory } from "../menu-items";

import activitiesNavs from "../../../features/activities/navigation";
import resourcesNavs from "../../../features/resources/navigation";
import schedulesNavs from "../../../features/schedules/navigation";

// ==============================|| MENU ITEMS - MAIN PAGES ||============================== //

const pages: IMenuItem = {
  id: "main-pages",
  title: "Manage",
  type: IMenuItemKind.Group,
  category: IMenuItemCategory.Main,
  children: [...schedulesNavs, ...activitiesNavs, ...resourcesNavs],
};

export default pages;
