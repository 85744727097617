import { IMenuItem, IMenuItemKind, IMenuItemCategory } from "../menu-items";
import authNavs from "../../../features/authentication/navigation";

// assets
import { ChromeOutlined, QuestionOutlined } from "@ant-design/icons";

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support: IMenuItem = {
  id: "support",
  title: "Support",
  type: IMenuItemKind.Group,
  category: IMenuItemCategory.Login,
  children: [
    ...authNavs,
    {
      id: "documentation-asana",
      title: "How to import",
      type: IMenuItemKind.Item,
      href: "https://codedthemes.gitbook.io/mantis-react/",
      icon: icons.QuestionOutlined,
      external: true,
      target: true,
    },
  ],
};

export default support;
