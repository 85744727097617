/* src/App.js */
import { withAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Routes from "./presentation/navigation/routes";
import ThemeCustomization from "./presentation/themes";
import ScrollTop from "./components/ScrollTop";

const App = () => {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Authenticator.Provider>
          <Routes />
        </Authenticator.Provider>
      </ScrollTop>
    </ThemeCustomization>
  );
};

export default withAuthenticator(App);
// export default App;
