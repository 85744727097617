import { Amplify } from "aws-amplify";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { store } from "./shared/store";

// third-party
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import awsconfig from "./aws-exports";

//Check if you are in localhost or production

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

if (isLocalhost) {
  const signInURI = awsconfig.oauth.redirectSignIn.split(",");
  const signOutURI = awsconfig.oauth.redirectSignOut.split(",");
  awsconfig.oauth.redirectSignIn = signInURI[0];
  awsconfig.oauth.redirectSignOut = signOutURI[0];
} else {
  awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
  awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
}

Amplify.configure(awsconfig);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
