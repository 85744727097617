import { lazy } from "react";

// project import
import Loadable from "../../../components/Loadable";

// assets
import { CoffeeOutlined } from "@ant-design/icons";

import {
  IMenuItem,
  IMenuItemKind,
} from "../../../presentation/navigation/menu-items";

// render - activities
const ActivitiesPage = Loadable(lazy(() => import("../ActivitiesPage")));
const ActivityPage = Loadable(lazy(() => import("../ActivityPage")));

// ==============================|| MENU ITEMS - MAIN PAGES ||============================== //

const items: IMenuItem[] = [
  {
    id: "activities",
    title: "Activities",
    type: IMenuItemKind.Item,
    href: "/activities",
    path: "activities",
    icon: CoffeeOutlined,
    element: <ActivitiesPage />,
    children: [
      {
        id: "resource-details",
        path: ":number",
        element: <ActivityPage />,
      },
    ],
  },
];

export default items;
