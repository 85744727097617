// project import
import { IMenuItem } from "./menu-items";

import dashboard from "./groups/dashboard";
import pages from "./groups/pages";
import support from "./groups/support";

// ==============================|| MENU ITEMS ||============================== //

interface IMenuItems {
  items: IMenuItem[];
}

const menuItems: IMenuItems = {
  items: [dashboard, pages, support],
};

export default menuItems;
