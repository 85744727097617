import { lazy } from "react";

// project import
import Loadable from "../../../components/Loadable";

// assets
import { ScheduleOutlined } from "@ant-design/icons";

import {
  IMenuItem,
  IMenuItemKind,
} from "../../../presentation/navigation/menu-items";

// render - schedules
const SchedulesPage = Loadable(lazy(() => import("../SchedulesPage")));
const SchedulePage = Loadable(lazy(() => import("../SchedulePage")));

// ==============================|| MENU ITEMS - MAIN PAGES ||============================== //

const items: IMenuItem[] = [
  {
    id: "Schedules",
    title: "Schedules",
    type: IMenuItemKind.Item,
    href: "/schedules",
    path: "schedules",
    icon: ScheduleOutlined,
    element: <SchedulesPage />,
    children: [
      {
        id: "schedule-details",
        path: ":number",
        element: <SchedulePage />,
      },
    ],
  },
];

export default items;
