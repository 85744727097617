import { lazy } from "react";

// project import
import Loadable from "../../../components/Loadable";

// assets
import { LoginOutlined, ProfileOutlined } from "@ant-design/icons";
import {
  IMenuItem,
  IMenuItemKind,
  IMenuItemCategory,
} from "../../../presentation/navigation/menu-items";

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
};

// render - resources
const Login = Loadable(lazy(() => import("../Login")));
const Register = Loadable(lazy(() => import("../Register")));

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const items: IMenuItem[] = [
  {
    id: "login1",
    title: "Login",
    type: IMenuItemKind.Item,
    path: "login",
    href: "/login",
    icon: icons.LoginOutlined,
    element: <Login />,
    target: true,
  },
  {
    id: "register1",
    title: "Register",
    type: IMenuItemKind.Item,
    path: "register",
    href: "/register",
    icon: icons.ProfileOutlined,
    element: <Register />,
    target: true,
  },
];

export default items;
