import { lazy } from "react";

// project import
import Loadable from "../../../components/Loadable";

// assets
import { HomeOutlined } from "@ant-design/icons";

import {
  IMenuItem,
  IMenuItemKind,
} from "../../../presentation/navigation/menu-items";

// render - home page
const HomePage = Loadable(lazy(() => import("../HomePage")));

// ==============================|| MENU ITEMS - MAIN PAGES ||============================== //

const items: IMenuItem[] = [
  {
    id: "default-dashboard",
    title: "Home",
    type: IMenuItemKind.Item,
    path: "/",
    href: "/",
    icon: HomeOutlined,
    element: <HomePage />,
    breadcrumbs: false,
  },
];

export default items;
