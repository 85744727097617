import { lazy } from "react";

// project import
import Loadable from "../../../components/Loadable";

// assets
import { TeamOutlined } from "@ant-design/icons";

import {
  IMenuItem,
  IMenuItemKind,
} from "../../../presentation/navigation/menu-items";

// render - resources
const ResourcesPage = Loadable(lazy(() => import("../ResourcesPage")));
const ResourcePage = Loadable(lazy(() => import("../ResourcePage")));

// ==============================|| MENU ITEMS - MAIN PAGES ||============================== //

const items: IMenuItem[] = [
  {
    id: "resources",
    title: "Resources",
    type: IMenuItemKind.Item,
    href: "/resources",
    path: "resources",
    icon: TeamOutlined,
    element: <ResourcesPage />,
    children: [
      {
        id: "resource-details",
        path: ":number",
        element: <ResourcePage />,
      },
    ],
  },
];

export default items;
