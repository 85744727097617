import React from "react";

export enum IMenuItemKind {
  Group = "group",
  Item = "item",
  Collapse = "collapse",
}
export enum IMenuItemCategory {
  Main,
  Login,
}
export interface IMenuItem {
  id: string;
  title?: string;
  type?: IMenuItemKind;
  href?: string;
  path?: string;
  category?: IMenuItemCategory;
  icon?: any;
  element?: React.ReactNode;
  breadcrumbs?: boolean;
  target?: boolean;
  external?: boolean;
  children?: IMenuItem[];
}
