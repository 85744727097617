// project import
import { IMenuItem, IMenuItemKind, IMenuItemCategory } from "../menu-items";
import homeNavs from "../../../features/home/navigation";

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard: IMenuItem = {
  id: "group-dashboard",
  title: "Dashboards",
  type: IMenuItemKind.Group,
  category: IMenuItemCategory.Main,
  children: homeNavs,
};

export default dashboard;
